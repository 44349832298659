import {
  confirmSignIn,
  getCurrentUser,
  updatePassword,
  signIn as AwsSignIn,
  signOut as AwsSignOut,
  resetPassword as AwsResetPassword,
  confirmResetPassword,
  fetchAuthSession
} from 'aws-amplify/auth'

import { useStateContext } from '../stateContext.js'
import { INIT_ASSETS } from '../Assets/AssetsReducer.js'
import {
  INIT_AUTH,
  SET_USER,
  SET_PASSWORD_CHANGE_REQUIRED,
  SET_FORGOT_PASSWORD,
  SET_FORGOT_PASSWORD_SUBMIT,
  SET_AUTHENTICATED,
} from './AuthReducer.js'
import { INIT_CONSTANTS } from '../Constants/ConstantsReducer.js'
import { INIT_CUSTOMERS } from '../Customer/CustomerReducer.js'
import { INIT_MAP } from '../Map/MapReducer.js'
import { INIT_PROJECTS } from '../Project/ProjectReducer.js'
import { INIT_REPORTS } from '../Reports/ReportsReducer.js'
import { INIT_SEARCH } from '../Search/SearchReducer.js'
import { INIT_UI } from '../UI/UIReducer.js'
import { INIT_USERS } from '../User/UserReducer.js'
import { safeGet } from '../../util.js'

export const useAuthActions = () => {
  const { state, dispatch } = useStateContext()

  const getGroups = () => {
    return (
      safeGet(
        'auth.user.tokens.accessToken.payload.cognito:groups',
        // 'auth.user.signInUserSession.accessToken.payload.cognito:groups',
        state,
      ) || []
    )
  }

  const memberOf = (groupQueries) => {
    const userGroups = getGroups()
    if (!(groupQueries instanceof Array)) {
      groupQueries = [groupQueries]
    }

    return !groupQueries.some(
      (groupQuery) =>
        !userGroups.find((userGroup) => userGroup.match(groupQuery)) &&
        userGroups.indexOf(groupQuery) < 0,
    )
  }

  const resetPassword = async ({ password }) => {
    try {
      await confirmSignIn({ challengeResponse: password })
      dispatch({
        type: SET_PASSWORD_CHANGE_REQUIRED,
        payload: { passwordChangeRequired: false },
      })
      dispatch({
        type: SET_AUTHENTICATED,
        payload: { authenticated: true },
      })
      const currentUser = await getCurrentUser()
      const session = await fetchAuthSession()
      dispatch({ type: SET_USER, payload: { user: { ...currentUser, ...session } } })

    } catch (err) {
      console.log(err)
    }
  }

  const changePassword = async ({ oldPassword, password }) => {
    await updatePassword({ oldPassword, newPassword: password })
  }

  const restoreSession = async () => {
    try {
      const currentUser = await getCurrentUser()
      const session = await fetchAuthSession()
      dispatch({ type: SET_USER, payload: { user: { ...currentUser, ...session } } })
      dispatch({ type: SET_AUTHENTICATED, payload: { authenticated: true } })
    } catch (err) {
      // fail silently: not signed in
      console.log(err)
    }
  }

  const signIn = async ({ email, password }) => {
    try {
      const userSignIn = await AwsSignIn({ username: email, password })
      // if (currentUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
      if (userSignIn.nextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {

        // signed in successfully
        dispatch({
          type: SET_AUTHENTICATED,
          payload: { authenticated: true },
        })
      } else {
        // requires password reset
        dispatch({
          type: SET_PASSWORD_CHANGE_REQUIRED,
          payload: { passwordChangeRequired: true },
        })
      }
      const session = await fetchAuthSession()
      const currentUser = await getCurrentUser()
      dispatch({ type: SET_USER, payload: { user: { ...currentUser, ...session } } })

    } catch (error) {
      throw new Error(error)
    }
  }

  const signOut = async () => {
    await AwsSignOut()

    dispatch({ type: INIT_ASSETS })
    dispatch({ type: INIT_AUTH })
    dispatch({ type: INIT_CONSTANTS })
    dispatch({ type: INIT_CUSTOMERS })
    dispatch({ type: INIT_MAP })
    dispatch({ type: INIT_PROJECTS })
    dispatch({ type: INIT_REPORTS })
    dispatch({ type: INIT_SEARCH })
    dispatch({ type: INIT_UI })
    dispatch({ type: INIT_USERS })
  }

  const forgotPassword = async () => {
    try {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: true },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const recoverPassword = async (email) => {
    await AwsResetPassword({ username: email.email }).then((data) => {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: false },
      })
      dispatch({
        type: SET_FORGOT_PASSWORD_SUBMIT,
        payload: { forgotPasswordSubmit: true },
      })
      console.log(data)
    })
  }

  const forgotPasswordSubmit = async (forgotPasswordForm) => {
    await confirmResetPassword({
      username: forgotPasswordForm.email,
      confirmationCode: forgotPasswordForm.verificationCode,
      newPassword: forgotPasswordForm.password,
    }
    ).then((data) => {
      dispatch({
        type: SET_FORGOT_PASSWORD_SUBMIT,
        payload: { forgotPasswordSubmit: false },
      })
      console.log(data)
    })
  }

  const rememberedPassword = async () => {
    try {
      dispatch({
        type: SET_FORGOT_PASSWORD,
        payload: { forgotPassword: false },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    getGroups,
    memberOf,
    resetPassword,
    changePassword,
    restoreSession,
    signIn,
    signOut,
    forgotPassword,
    recoverPassword,
    forgotPasswordSubmit,
    rememberedPassword,
    userState: state.auth.user,
  }
}
