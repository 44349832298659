/* eslint-disable */

export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        description
        status
        userGroups
        adminGroups
      }
      nextToken
    }
  }
`

export const getAssetsAndHistoryByCustomer = /* GraphQL */ `
  query GetAssetsAndHistoryByCustomer(
    $id: ID!
    $assetNextToken: String
    $limit: Int
  ) {
    getCustomer(id: $id) {
      id

      assets(limit: $limit, nextToken: $assetNextToken) {
        items {
          id
          assetHistory(limit: 1, sortDirection: DESC) {
            items {
              id
              uuid
              deviceId
              assetId
              eventTs
              receivedTs
              altitude
              battery
              rssi
              accelerometer
              speed
              course
              coordinate {
                latitude
                longitude
              }
            }
          }
        }
        nextToken
      }
    }
  }
`

export const listAssetTypesAndSubTypes = /* GraphQL */ `
  query ListAssetTypes(
    $filter: ModelAssetTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subType(limit: $limit) {
          items {
            id
            name
            typeId
            iconName
            idleThreshold
            offlineThreshold
          }
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
      }
      nextToken
    }
  }
`

export const listAssetHistorys = /* GraphQL */ `
  query ListAssetHistorys(
    $assetId: ID
    $eventTs: ModelIntKeyConditionInput
    $filter: ModelAssetHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetHistorys(
      assetId: $assetId
      eventTs: $eventTs
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        assetId
        eventTs
        uuid
        deviceId
        unitNumber
        customerId
        receivedTs
        coordinate {
          latitude
          longitude
        }
        altitude
        battery
        rssi
        accelerometer
        speed
        course
        projectId
      }
      nextToken
    }
  }
`

export const getActiveCustomer = /* GraphQL */ `
  query GetActiveCustomer(
    $id: ID!
    $assetNextToken: String
    $projectNextToken: String
    $assetFilter: ModelAssetFilterInput
  ) {
    getCustomer(id: $id) {
      id
      name
      email
      description
      status
      projects(limit: 500, nextToken: $projectNextToken) {
        items {
          id
          name
          customerId
          client
          description
          startDate
          endDate
          boundaries {
            latitude
            longitude
          }
          overlays {
            name
            file {
              bucket
              key
              region
            }
          }
          status
        }
        nextToken
      }
      assets(limit: 500, filter: $assetFilter, nextToken: $assetNextToken) {
        items {
          id
          uuid
          customerId
          deviceId
          name
          description
          version
          unitNumber
          make
          model
          year
          capacity
          createdAt
          activatedOn
          deactivatedOn
          status
          toolType
          size
          annualInspection
          eventTs
          receivedTs
          coordinate {
            latitude
            longitude
          }
          altitude
          battery
          rssi
          accelerometer
          speed
          course
          projectId
          type {
            id
            name
            iconName
            idleThreshold
            offlineThreshold
          }
          subType {
            id
            name
            typeId
            iconName
            idleThreshold
            offlineThreshold
          }
          dimensions {
            id
            name
          }
          supplier {
            id
            name
          }
        }
        nextToken
      }
    }
  }
`
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      id
      customerId
      uuid
      deviceId
      name
      userGroups
      adminGroups
      description
      version
      status
      unitNumber
      make
      model
      year
      capacity
      createdAt
      activatedOn
      deactivatedOn
      toolType
      size
      annualInspection

      type {
        id
        name
        subType {
          nextToken
        }
        iconName
        idleThreshold
        offlineThreshold
      }
      subType {
        id
        name
        typeId
        iconName
        idleThreshold
        offlineThreshold
      }
      dimensions {
        id
        name
      }
      supplier {
        id
        name
      }
      eventTs
      receivedTs
      coordinate {
        latitude
        longitude
      }
      altitude
      battery
      rssi
      accelerometer
      speed
      course
      projectId
    }
  }
`

export const projectsByCustomerId = /* GraphQL */ `
  query ProjectsByCustomerId(
    $customerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        customerId
        client
        description
        boundaries {
          latitude
          longitude
        }
        overlays {
          name
          file {
            bucket
            key
            region
          }
        }
        startDate
        endDate
        status
      }
      nextToken
    }
  }
`
