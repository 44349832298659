import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
} from '@mui/material'
import PropTypes from 'prop-types'
import SpinnerButton from '../elements/SpinnerButton.js'
import { useForm } from '../../hooks/Forms.js'
import { reduceAssetTypesToNames } from '../../util.js'
import { useConstantsActions } from '../../store/Constants/ConstantsActions.js'
import AssetIconSelect from '../elements/AssetIconSelect.js'
import { useStateContext } from '../../store/stateContext.js'
import { makeStyles, useTheme } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  errorMsg: {
    color: theme.palette.error.main,
  },
  form: {
    backgroundColor: theme.colors.backgroundColor,
    color: 'white'
  },
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      border: `1px solid ${theme.colors.lightSteel}`,
    },
  },

}))

const EditAssetTypeDialog = ({
  assetType = {},
  closeForm = () => { },
  onSave = () => { },
  open,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { state } = useStateContext()
  const assetTypesState = state.constants.assetTypes
  const [assetTypeNames, setAssetTypeNames] = useState([])
  const { updateAssetType } = useConstantsActions()

  const {
    clearFormState,
    formState,
    getFieldError,
    getValue,
    handleInputChange,
    handleSubmit,
    isValid,
    validateForm,
  } = useForm({
    name: `required|unique:${assetTypeNames.join(',')}`,
    iconName: 'required',
    idleThreshold: 'number:>0',
    offlineThreshold: 'number:>0',
  })

  useEffect(() => {
    // Set up the validation rules
    // for the name using the assetTypes
    const allTypes = reduceAssetTypesToNames(assetTypesState)
    if (assetType.name) {
      allTypes.splice(allTypes.indexOf(assetType.name), 1)
    }
    setAssetTypeNames(allTypes)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypesState, assetType.name])

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypeNames])

  const handleCancelForm = () => {
    closeForm()
    clearFormState()
  }

  const onSubmit = async ({
    name,
    iconName,
    idleThreshold,
    offlineThreshold,
  }) => {
    const newAssetType = await updateAssetType({
      assetType,
      name,
      iconName,
      idleThreshold,
      offlineThreshold,
    })

    closeForm()
    clearFormState()
    onSave(newAssetType)
  }

  return (
    <Dialog fullWidth onClose={closeForm} open={open} className={classes.dialog}>
      <form onSubmit={(e) => handleSubmit(onSubmit, e)} className={classes.form}>
        <DialogTitle>Edit {assetType.name}</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} sx={{marginTop: 1}}>
            <Grid2  xs={2} flexGrow={1}>
              <AssetIconSelect
                onChange={handleInputChange('iconName')}
                value={getValue('iconName', assetType.iconName)}
              />
            </Grid2>
            <Grid2  xs={6} size={9}>
              <TextField
                error={!!getFieldError('name')}
                helperText={getFieldError('name')}
                fullWidth
                label='Type'
                onChange={handleInputChange('name')}
                value={getValue('name', assetType.name)}
                variant='outlined'
                sx={theme.textfield}
              />
            </Grid2>
            <Grid2  xs={6} size={6}>
              <TextField
                error={!!getFieldError('idleThreshold')}
                helperText={getFieldError('idleThreshold')}
                fullWidth
                label='Idle Threshold (days)'
                onChange={handleInputChange('idleThreshold')}
                type='number'
                value={getValue('idleThreshold', assetType.idleThreshold)}
                variant='outlined'
                sx={theme.textfield}
              />
            </Grid2>
            <Grid2  xs={4} size={6}>
              <TextField
                error={!!getFieldError('offlineThreshold')}
                helperText={getFieldError('offlineThreshold')}
                fullWidth
                label='Offline Threshold (hours)'
                onChange={handleInputChange('offlineThreshold')}
                type='number'
                value={getValue('offlineThreshold', assetType.offlineThreshold)}
                variant='outlined'
                sx={theme.textfield}
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={handleCancelForm}>
            Cancel
          </Button>
          <SpinnerButton
            loading={formState.loading}
            type='submit'
            valid={isValid()}
          >
            Submit
          </SpinnerButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

EditAssetTypeDialog.propTypes = {
  assetType: PropTypes.object,
  closeForm: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
}

export default EditAssetTypeDialog
