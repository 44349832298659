import { useEffect, useMemo, useState } from 'react'
import SuperCluster from 'supercluster'
import { useStateContext } from '../store/stateContext.js'

const useAssetSuperCluster = () => {
  const { state } = useStateContext()

  const [clusters, setClusters] = useState([])
  const [zoomLevel, setZoomLevel] = useState(0)

  useEffect(() => {
    setZoomLevel(state.map.viewport.zoom)
  }, [state.map.viewport.zoom])

  const supercluster = useMemo(
    () => new SuperCluster({ radius: 40, maxZoom: 16, minZoom: 0 }),
    [],
  )

  // load icons to the super cluster
  useEffect(() => {
    let icons = Object.values(state.map.iconLayers)
    const transformedData = icons.map((item) => ({
      geometry: {
        coordinates: [item.coordinates.longitude, item.coordinates.latitude],
      },
      properties: {
        id: item.id,
        icon: item.icon ?? '',
        unitNumber: item.unitNumber,
        typeName: item.typeName,
        subTypeName: item.subTypeName,
        lastReceived: item.lastReceived,
        onClick: item.onClick,
      },
    }))

    supercluster.load(transformedData)
  }, [state.map.iconLayers, supercluster])

  useEffect(() => {
    const bounds = [-180, -85, 180, 85]
    setClusters(supercluster.getClusters(bounds, Math.floor(zoomLevel)))
  }, [zoomLevel, supercluster, state.map.iconLayers])

  return { clusters, supercluster, setZoomLevel }
}

export default useAssetSuperCluster
