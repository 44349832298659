import React, { useState, createRef } from 'react'
import {
  Divider,
  Toolbar,
  FormControlLabel,
  Switch,
  Box,
  Tabs,
  Tab,
  useTheme,
  Grid2,
  Typography,
  Button
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useStateContext } from '../../store/stateContext.js'
import ToolbarToggleButton from './ToolbarToggleButton.js'
import MapFilterMenu from './MapFilterMenu.js'
import SystemIcon from './SystemIcon.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js'


const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    boxSizing: 'border-box',
    borderBottomColor: theme.colors.lightSteel,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    zIndex: 99,
    backgroundColor: theme.colors.backgroundColor
  },
  toolbarIconButton: {
    padding: 8,
  },
  fullScreenToggle: {
    color: 'white',
  },
  openOverlayDrawerButton: {
    marginLeft: 'auto',
  },
  tab: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    minHeight: 'unset',
    minWidth: theme.spacing(9),
    '& svg': {
      marginBottom: '0 !important',
      width: 20,
      height: 20,
    },
  },
  datepicker: {

    '& .MuiInputBase-root': {
      color: 'white',
      height: 40,
      width: 175,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      borderRadius: 10,
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
  },
}))

const ProjectToolbar = ({
  assetsFilter,
  setAssetsFilter,
  assetTypeList,
  setAnomalyFilter,
  anomalyFilter,
  handleTabChange,
  setAssetTypeList,
  curTab,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const assetsFilterButton = createRef()
  const { state } = useStateContext()
  const [openMenu, setOpenMenu] = useState(null)
  const { toggleFullScreenProjectMap } =
    useUIActions()
  const handleToolbarButtonClick = (event) => {
    setOpenMenu(event.currentTarget.id)
  }
  const handleClose = () => {
    setOpenMenu(null)
  }

  const handelAnomalyFilterChange = (event, anomaly) => {
    if (anomalyFilter === anomaly) {
      setAnomalyFilter(null)
      return
    }
    setAssetsFilter([])
    setAnomalyFilter(anomaly)
  }
  const isLit = (filter) => {
    if (!filter || !filter.checkFilter) return
    return filter.checkFilter.length > 0
  }
  const curTabMap = curTab === 0

  return (
    <Toolbar variant='dense' className={classes.toolbar}>
      <Tabs
        className={classes.tabs}
        value={curTab}
        onChange={handleTabChange}
        variant='standard'
        indicatorColor='primary'
        textColor='secondary'
      >
        <Tab
          className={classes.tab}
          sx={{ color: 'white' }}
          label='Map'
          icon={<SystemIcon name='icn_map' color={'white'} />}
        />
        <Tab
          className={classes.tab}
          sx={{ color: 'white' }}
          label='Moves'
          icon={<SystemIcon name='icn_moves' color={'white'} />}
        />
      </Tabs>
      <Divider
        orientation='vertical'
        flexItem
        variant='fullWidth'
        sx={{ backgroundColor: theme.colors.lightSteel, }}
      />
      {curTabMap && (
        <Grid2 container sx={{ height: '100%', flexGrow: 1, paddingTop: 0.5 }}>
          <Grid2 container sx={{ paddingX: 1 }} >
            <Box sx={{ paddingY: 2 }}>
              <ToolbarToggleButton
                lit={isLit(assetsFilter)}
                id='assetsFilter'
                icon='#icn_assets'
                ref={assetsFilterButton}
                onClick={handleToolbarButtonClick}
              />
            </Box>
            <MapFilterMenu
              open={openMenu === 'assetsFilter'}
              onClose={handleClose}
              onChange={setAssetsFilter}
              getData={assetTypeList}
              setData={setAssetTypeList}
              anchor={assetsFilterButton}
              filters={assetsFilter}
              setAnomalyFilter={setAnomalyFilter}
              defaultChecked
            />
            <Divider
              orientation='vertical'
              flexItem
              variant='fullWidth'
              sx={{ backgroundColor: theme.colors.lightSteel, marginLeft: 2 }}
            />
            <Box sx={{ paddingY: 2 }}>
              <ToolbarToggleButton
                icon='#icn_battery_alert'
                lit={anomalyFilter === 'Low Battery'}
                onClick={(e) => {
                  handelAnomalyFilterChange(e, 'Low Battery')
                }}
              />
            </Box>
            <Box sx={{ paddingY: 2 }}>
              <ToolbarToggleButton
                icon='#icn_signal_wifi_off'
                lit={anomalyFilter === 'Lost Connection'}
                onClick={(e) => {
                  handelAnomalyFilterChange(e, 'Lost Connection')
                }}
              />
            </Box>
          </Grid2>
          <Grid2
            container
            sx={{
              // display: { xs: 'none', sm: 'block' },
              // height: '100%',
              flexGrow: 1,
              justifyContent: 'flex-end',
              paddingRight: !state.ui.projectMapFullScreen ? theme.spacing(42) : 0,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  size='medium'
                  color='primary'
                  checked={state.ui.projectMapFullScreen}
                  onChange={toggleFullScreenProjectMap}
                />
              }
              className={classes.fullScreenToggle}
              label='Full Screen'
              labelPlacement='start'
            />
          </Grid2>
          {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <IconButton
              size='small'
              onClick={openTempDrawerMapOverlays}
              className={classes.openOverlayDrawerButton}
            >
              <SvgIcon color='secondary'>
                <svg>
                  <use xlinkHref={Icons + '#icn_tree'} />
                </svg>
              </SvgIcon>
            </IconButton>
          </Box> */}
        </Grid2>
      )}
      {
        !curTabMap && (
          <Grid2
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              height: '100%',
              alignItems: 'center',
              paddingLeft: 2,
              paddingTop: 1,
              color: 'white'
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography>Display movements from</Typography>
              <DatePicker className={classes.datepicker} />
              <Typography>to</Typography>
              <DatePicker className={classes.datepicker} />
            </LocalizationProvider>
            <Box
              sx={{
                // backgroundColor: 'blue',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >

              <Button
                variant='contained'
                sx={{ borderRadius: 5 }}
                startIcon={
                  <SystemIcon name='icn_report' color='secondary' />
                }>
                Export Report
              </Button>

            </Box>
          </Grid2>
        )
      }
    </Toolbar>
  )
}

export default ProjectToolbar
