/* eslint-disable no-unused-vars */

import { useEffect, useRef, useState } from 'react'
import { boundsViewport, safeGet } from '../../util.js'
import { useStateContext } from '../stateContext.js'
import {
  SET_VIEWPORT,
  UPDATE_VIEWPORT,
  SET_GEOJSONLAYERS,
  ADD_GEOJSONLAYERS,
  REMOVE_GEOJSONLAYERS,
  SET_WAYPOINTLAYERS,
  SET_PATHLAYERS,
  SET_ICONLAYERS,
  SET_TOOLTIP,
  SET_FILTERED_LAYERS,
  UPDATE_CURRENT_MAPID,
} from './MapReducer.js'
import MovesLayer from '../../map-layers/MovesLayer.js'
import BoundaryLayer from '../../map-layers/BoundaryLayer.js'
import OverlayLayer from '../../map-layers/OverlayLayer.js'
import { WebMercatorViewport } from '@deck.gl/core'
import { IconLayer, TextLayer } from '@deck.gl/layers'
import AssetLayer from '../../map-layers/AssetLayer.js'
import ClusterBubble from '../../assets/img/svg/cluster_bubble.svg'
import useAssetSuperCluster from '../../hooks/useAssetSuperCluster.js'

export const MAX_ZOOM = 16

export const useMapActions = () => {
  const { state, dispatch } = useStateContext()
  const mapRef = useRef()
  const [iconMapping, setIconMapping] = useState(null)
  const { clusters, supercluster, setZoomLevel } = useAssetSuperCluster()

  //TODO: create a separate script to do this once and save it in the project
  useEffect(() => {
    fetch('/icons/icons.json')
      .then((res) => res.json())
      .then((data) => {
        const mapping = {}
        data.frames.forEach((frame) => {
          mapping[frame.filename] = {
            x: frame.frame.x,
            y: frame.frame.y,
            width: frame.frame.w,
            height: frame.frame.h,
            anchorX: frame.frame.w / 2,
            anchorY: 27,
          }
        })
        setIconMapping(mapping)
      })
      .catch((err) => console.log(err))
  }, [])

  // update filteredLayers
  useEffect(() => {
    const filteredLayers = []
    if (state.map.geoJsonLayers && state.map.geoJsonLayers.length > 0) {
      filteredLayers.push(
        new OverlayLayer({
          data: state.map.geoJsonLayers,
        }),
      )
    }
    //  }

    // create paths (project boundaries)
    if (state.map.pathLayers) {
      let paths = Object.values(state.map.pathLayers)

      filteredLayers.push(
        new BoundaryLayer({
          id: `boundary-layer-${filteredLayers.length}`,
          data: paths,
          pickable: true,
          suppressIcon: state.map.suppressIcon,
          onTooltip: (tooltip) => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      )
    }

    // create moves layers
    if (state.map.waypointLayers) {
      let waypoints = Object.values(state.map.waypointLayers)

      filteredLayers.push(
        new MovesLayer({
          id: 'waypoint-layer',
          data: waypoints,
          pickable: true,
          onTooltip: (tooltip) => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      )
    }

    // create icon layer
    if (clusters && iconMapping) {
      const iconLayers = [
        new IconLayer({
          id: 'clusters-layer',
          data: clusters.filter((d) => d.properties.cluster),
          getIcon: () => {
            return {
              url: ClusterBubble,
              width: 40,
              height: 49,
              anchorY: 49,
            }
          },
          getPosition: (d) => d.geometry.coordinates,
          getSize: 55,
          onClick: (info) => {
            if (info.object && info.object.properties.cluster) {
              const expansionZoom = supercluster.getClusterExpansionZoom(
                info.object.properties.cluster_id,
              )
              setZoomLevel(expansionZoom)
            }
          },
        }),

        new TextLayer({
          data: clusters.filter((d) => d.properties.cluster),
          fontWeight: 700,
          getAlignmentBaseline: 'center',
          getColor: () => [255, 255, 255, 255],
          getPixelOffset: [0, -24],
          getPosition: (d) => d.geometry.coordinates,
          getSize: 14,
          getText: (d) =>
            String(safeGet('properties.point_count_abbreviated', d) || ''),
          getTextAnchor: 'middle',
          id: `cluster-text-layer`,
          pickable: true,
        }),

        new AssetLayer({
          id: 'asset-layer',
          data: clusters.filter((d) => !d.properties.cluster),
          iconMapping,
          pickable: true,
          getPosition: (d) => {
            return d.geometry.coordinates
          },
          onClick(info, event) {
            const callback = safeGet('object.properties.onClick', info)
            if (typeof callback === 'function') {
              return callback(info, event)
            }
          },
          onTooltip: (tooltip) => {
            dispatch({ type: SET_TOOLTIP, payload: { tooltip } })
          },
        }),
      ]
      filteredLayers.push(iconLayers)
    }
    dispatch({
      type: SET_FILTERED_LAYERS,
      payload: { filteredLayers },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.map.geoJsonLayers,
    // state.map.iconLayers,
    state.map.pathLayers,
    state.map.suppressIcon,
    state.map.waypointLayers,
    clusters,
    supercluster,
  ])

  const setViewport = async (viewport) => {
    dispatch({ type: SET_VIEWPORT, payload: { viewport } })
  }

  const updateCurrentMapId = async (id) => {
    dispatch({ type: UPDATE_CURRENT_MAPID, payload: { id } })
  }

  const updateViewport = async (viewport) => {
    dispatch({ type: UPDATE_VIEWPORT, payload: { viewport } })
  }

  const visibleOnMap = (coordinates) => {
    const viewportInstance = new WebMercatorViewport(state.map.viewport)
    const bounds = viewportInstance.getBounds()

    if (!coordinates) {
      return false
    }

    const { latitude, longitude } = coordinates

    // filter bad results
    if (!latitude || !longitude) {
      return false
    }
    return (
      longitude >= bounds[0] &&
      longitude <= bounds[2] &&
      latitude >= bounds[1] &&
      latitude <= bounds[3]
    )
  }

  const zoomToCoordinates = async (coordinates) => {
    const { latitude, longitude, zoom } =
      boundsViewport(coordinates, state.map.viewport) || {}

    if (
      !latitude ||
      !longitude ||
      !zoom ||
      !state.map.viewport.width ||
      !state.map.viewport.height
    ) {
      return
    }

    const viewport = {
      ...state.map.viewport,
      latitude,
      longitude,
      zoom: zoom > MAX_ZOOM ? MAX_ZOOM : zoom,
    }
    setViewport(viewport)

    return viewport
  }

  const clearLayers = () => {
    dispatch({
      type: SET_GEOJSONLAYERS,
      payload: {
        geoJsonLayers: [],
      },
    })

    dispatch({
      type: SET_WAYPOINTLAYERS,
      payload: {
        waypointLayers: {},
      },
    })

    dispatch({
      type: SET_ICONLAYERS,
      payload: {
        iconLayers: {},
      },
    })

    dispatch({
      type: SET_PATHLAYERS,
      payload: {
        pathLayers: {},
      },
    })
  }

  const addGeoJsonLayer = (id, geoJson) => {
    dispatch({
      type: ADD_GEOJSONLAYERS,
      payload: {
        geoJsonLayer: geoJson,
      },
    })
  }

  const removeGeoJsonLayer = (id, geoJson) => {
    dispatch({
      type: REMOVE_GEOJSONLAYERS,
      payload: {
        geoJsonLayer: geoJson,
      },
    })
  }

  const addWaypointLayer = (id, points) => {
    //console.log(points)
    dispatch({
      type: SET_WAYPOINTLAYERS,
      payload: {
        waypointLayers: {
          ...state.map.waypointLayers,
          [id]: points,
        },
      },
    })
  }

  return {
    addWaypointLayer,
    addGeoJsonLayer,
    removeGeoJsonLayer,
    clearLayers,
    mapRef,
    setViewport,
    updateCurrentMapId,
    updateViewport,
    visibleOnMap,
    zoomToCoordinates,
  }
}
