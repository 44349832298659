import React, { useEffect, useRef } from 'react'
import { useForm } from '../../hooks/Forms.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { makeStyles } from '@mui/styles'
import { Grid2, Button, TextField, Typography } from '@mui/material'
import SpinnerButton from '../elements/SpinnerButton.js'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 8,
    marginTop: 8,
    maxWidth: theme.spacing(32),
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
  textField: {
    '& .MuiInputBase-input': {
      color: 'white',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)})`,
      // transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '35ch',
        // '&:focus': {
        //   width: '20ch',
        // },
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.lightSteel,
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.colors.lightSteel,
      fontWeight: 'bold',
    },
  },
}))

export default () => {
  const emailRef = useRef(null)
  const classes = useStyles()

  const {
    formState,
    getFieldError,
    handleSubmit,
    handleInputChange,
    isValid,
    setFormState,
  } = useForm({
    email: 'required|email',
    password: 'required',
  })

  const { signIn, forgotPassword } = useAuthActions()

  const handelForgotPassword = () => {
    forgotPassword()
  }

  useEffect(() => {
    setFormState((prev) => ({ ...prev, untouched: false }))
  }, [setFormState])

  return (
    <>
      <form onSubmit={(e) => handleSubmit(signIn, e)} data-test='loginForm'>
        {formState.error && (
          <Grid2 className={classes.errorMessage}>
            <Typography
              variant='body1'
              color='error'
              data-test='formErrorMessage'
            >
              {formState.error}
            </Typography>
          </Grid2>
        )}
        <Grid2 className={classes.verticalGutters}>
          <TextField
            fullWidth
            autoFocus
            data-test='emailField'
            margin='normal'
            placeholder='USERNAME'
            error={!!getFieldError('email')}
            onChange={handleInputChange('email')}
            variant='outlined'
            className={classes.textField}
            inputRef={emailRef}
          />
        </Grid2>
        <Grid2 className={classes.verticalGutters}>
          <TextField
            fullWidth
            data-test='passwordField'
            placeholder='PASSWORD'
            error={!!getFieldError('password')}
            margin='normal'
            onChange={handleInputChange('password')}
            type='password'
            variant='outlined'
            className={classes.textField}
          />
        </Grid2>
        <Grid2 align='center' className={classes.verticalGutters}>
          <SpinnerButton
            data-test='signinButton'
            type='submit'
            valid={isValid()}
            loading={formState.loading}
          >
            Login
          </SpinnerButton>
        </Grid2>
        <Grid2 align='center' className={classes.verticalGutters}>
          <Button onClick={handelForgotPassword}>Forgotten Password?</Button>
        </Grid2>
      </form>
    </>
  )
}
