/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useMemo } from 'react'
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AssetIcon from './AssetIcon.js'
import SystemIcon from './SystemIcon.js'
import { safeGet } from '../../util.js'
import { useStateContext } from '../../store/stateContext.js'
import { WebMercatorViewport } from '@deck.gl/core'
import { debounce } from 'lodash'

const useStyles = makeStyles((theme) => ({
  cell: {
    // border: 'none',
  },
  list: {
    maxHeight: '340px',
    overflow: 'auto',
    backgroundColor: theme.colors.backgroundColor,
    color: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.lightSteel,
  },
  nested: {
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      paddingRight: theme.spacing(1),
    },
  },
  parentItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  childItem: {
    paddingLeft: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
  },
  text: {
    paddingRight: theme.spacing(1),
  },
}))

const consolidateInventory = (inventory) => {
  const types = {}

  for (let item of inventory) {
    const typeName = safeGet('type.name', item) || 'Unknown'
    const subTypeName = safeGet('subType.name', item) || 'Unknown'

    if (!types[typeName]) {
      types[typeName] = {
        type: item.type,
        name: typeName.toUpperCase(),
        amount: 0,
        subTypes: {},
      }
    }

    types[typeName].amount++

    if (typeName !== subTypeName) {
      if (!types[typeName].subTypes[subTypeName]) {
        types[typeName].subTypes[subTypeName] = {
          subType: item.subType,
          name: subTypeName.toUpperCase(),
          amount: 0,
        }
      }

      types[typeName].subTypes[subTypeName].amount++
    }
  }

  return Object.values(types)
}

const InventoryPanel = ({ className, inventory = [] }) => {
  const classes = useStyles()
  const [list, setList] = useState([])
  const [opened, setOpened] = useState({})
  const { state } = useStateContext()

  const setAssetList = useMemo(
    () =>
      debounce(() => {
        const viewportInstance = new WebMercatorViewport(state.map.viewport)
        const bounds = viewportInstance.getBounds()
        const visibles = inventory.filter((asset) => {
          const { latitude, longitude } = asset.coordinate
          return (
            longitude >= bounds[0] &&
            longitude <= bounds[2] &&
            latitude >= bounds[1] &&
            latitude <= bounds[3]
          )
        })
        setList(consolidateInventory(visibles))
      }, 50),
    [state.map.viewport, inventory],
  )

  useEffect(() => {
    setAssetList()
    return () => setAssetList.cancel()
  }, [setAssetList])

  return (
    <Paper className={className} elevation={8} sx={{ borderRadius: 3 }}>
      <List className={classes.list} sx={{ borderRadius: 2 }}>
        {list.length === 0 && (
          <ListItem
            className={classes.parentItem}
            secondaryAction={<span>0</span>}
          >
            <ListItemIcon>
              <SystemIcon name='icn_assets' color='white' />
            </ListItemIcon>
            <ListItemText primary='ASSETS' />
          </ListItem>
        )}
        {list.length > 0 &&
          list
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((item, index) => (
              <div key={`wrapper-index${index}`}>
                <ListItem
                  className={classes.parentItem}
                  key={`inventory-item-${index}`}
                  onClick={() =>
                    setOpened({
                      ...opened,
                      [item.name]: !opened[item.name],
                    })
                  }
                  secondaryAction={item.amount}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <>
                        {Object.keys(item.subTypes).length > 0 &&
                          (opened[item.name] ? (
                            <ExpandMoreIcon sx={{ fill: 'white' }} />
                          ) : (
                            <ChevronRightIcon sx={{ fill: 'white' }} />
                          ))}

                        <AssetIcon name={safeGet('type.iconName', item)} />
                      </>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      className={classes.text}
                    />
                  </ListItemButton>
                </ListItem>

                <Collapse
                  key={`inventory-list-collapse-${index}`}
                  className={classes.nested}
                  in={opened[item.name]}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    {Object.values(item.subTypes)
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(
                        (childItem, childIndex) =>
                          childItem.name !== item.name && (
                            <ListItem
                              className={classes.childItem}
                              key={`inventory-child-${index}-${childIndex}`}
                              secondaryAction={childItem.amount}
                            >
                              <ListItemIcon>
                                <AssetIcon
                                  name={safeGet('subType.iconName', childItem)}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                className={classes.text}
                              />
                            </ListItem>
                          ),
                      )}
                  </List>
                </Collapse>
              </div>
            ))}
      </List>
    </Paper>
  )
}

export default InventoryPanel
