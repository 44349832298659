import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translate(-50%, -10%)',
    minWidth: theme.spacing(12),
    backgroundColor: theme.colors.backgroundColor,
    position: 'absolute',
    zIndex: 1500,
    pointerEvents: 'none',
    border: '1px solid',
    borderColor: theme.colors.lightSteel
  },
  cardContent: {
    paddingBottom: theme.spacing(2) + 'px !important',
    backgroundColor: theme.colors.backgroundColor,

  },
  title: {
    color: 'white'
  },
  client: {
    color: theme.colors.portlandCement,
  },
}))

const ProjectTooltip = ({ tooltip }) => {
  const classes = useStyles()

  if (!tooltip) {
    return null
  }
  return (
    <Card
      className={classes.root}
      elevation={12}
      style={{
        left: tooltip.x,
        top: tooltip.y,
      }}
    >
      <CardContent className={classes.cardContent}>
        <Typography variant='h6' className={classes.title}>
          {tooltip.name}
        </Typography>
        <Typography variant='subtitle1' className={classes.client}>
          {tooltip.client}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ProjectTooltip
