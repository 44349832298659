import React, { useEffect, useState } from 'react'

import moment from 'moment'

import {
  Container,
  Drawer,
  Typography,
  Grid2,
  IconButton,
  Box,
  Button,
  Popover
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'

import SystemIcon from './SystemIcon.js'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useStateContext } from '../../store/stateContext.js'
import { safeGet } from '../../util.js'
import { useUIActions } from '../../store/UI/UIActions.js'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      maxHeight: '90vh',
      minHeight: '85vh',
      borderTopWidth: 1,
      borderTopColor: theme.colors.lightSteel,
      borderTopStyle: 'solid',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: theme.colors.backgroundColor
    },
  },
  title: {
    color: theme.colors.portlandCement,
    textTransform: 'uppercase',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  tableWrap: {
    '& .MuiPaper-elevation2': {
      boxShadow: 'none',
    },
  },
  popover: {
    paddingTop: 10,
    paddingBottom: 10,
    width: 150,
  },

}))

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const columns = [
  { header: 'UnitId', accessorKey: 'unitNumber' },
  { header: 'Type', accessorKey: 'type' },
  { header: 'Subtype', accessorKey: 'subType' },
  {
    header: 'Time Since Last Checkin',
    accessorKey: 'offlineFor',
    cellStyle: { minWidth: '90px' },
  },
  {
    header: 'Last Checked Date Time',
    accessorKey: 'lastCheckedIn',
    cellStyle: { minWidth: '90px' },
  },
  {
    header: 'Initial Checkin',
    accessorKey: 'firstCheckedIn',
    cellStyle: { minWidth: '90px' },
  },
  {
    header: 'UUID',
    accessorKey: 'uuid',
    cellStyle: { minWidth: '136px' },
  },
  { header: 'Lat', accessorKey: 'latitude' },
  { header: 'Long', accessorKey: 'longitude' },
  { header: 'ProjectId', accessorKey: 'project' },
]

export default ({ anomaly, data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { closeDrawerAnomaly } = useUIActions()
  const {
    state: { ui: uiState },
  } = useStateContext()
  const [tableData, setTableData] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl)

  const massageData = (data) => {
    const formattedData = data.reduce((acc, current) => {
      const lastCheckedIn = moment.unix(safeGet('eventTs', current, ''))

      acc.push({
        name: safeGet('name', current, ''),
        activatedOn: safeGet('activatedOn', current, ''),
        latitude: safeGet('coordinate.latitude', current, ''),
        longitude: safeGet('coordinate.longitude', current, ''),
        battery: safeGet('battery', current, ''),
        rssi: safeGet('rssi', current, ''),
        nextToken: safeGet('nextToken', current, ''),
        assetMovement: safeGet('assetMovement', current, ''),
        customer: safeGet('customerId', current, ''),
        deactivatedOn: safeGet('deactivatedOn', current, ''),
        description: safeGet('description', current, ''),
        lastCheckedIn: lastCheckedIn.format('M/D/YY hh:mm:ss'),
        firstCheckedIn: moment
          .unix(safeGet('firstCheckedIn', current, ''))
          .format('M/D/YY hh:mm:ss'),
        id: safeGet('id', current, ''),
        project: safeGet('projectId', current, ''),
        subType: safeGet('subType.name', current, ''),
        type: safeGet('type.name', current, ''),
        uuid: safeGet('uuid', current, ''),
        offlineFor: lastCheckedIn.fromNow(),
        deviceNumber: safeGet('deviceNumber', current, ''),
        unitNumber: safeGet('unitNumber', current, ''),
      })

      return acc
    }, [])
    return formattedData
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const anomalyTable = useMaterialReactTable({
    columns: columns,
    data: tableData ?? [],
    enableColumnActions: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{
        display: 'flex',
        gap: '16px',
        padding: '8px',
        flexWrap: 'wrap',
      }}>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleClick}
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
      </Box>
    ),
    mrtTheme: {
      baseBackgroundColor: theme.colors.backgroundColor,

    },
    muiTablePaperProps: {
      elevation: 0,
      color: 'white',
      sx: {
        backgroundColor: theme.colors.backgroundColor,
        '& .MuiTableCell-root': {
          color: 'white',
        },
        '& .MuiTableHead-cell': {
          color: theme.colors.lightSteel,
        },
        '& .MuiTableBody-root': {
          color: 'white',
        },
        '& .MuiPaginationItem-root': {
          color: 'white',
        },
        '& .MuiTablePagination-root': {
          color: 'white',
          '& .MuiTablePagination-caption': {
            color: 'white',
          },

        },
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
      },
    }
  });

  const prepareExportTableData = () => {
    const rows = anomalyTable.getPrePaginationRowModel().rows
    const data = rows.map((row) => ({
      UnitId: row.original.unitNumber,
      Type: row.original.type,
      Subtype: row.original.subType,
      OfflineFor: row.original.offlineFor,
      LastCheckedIn: row.original.lastCheckedIn,
      FirstCheckedIn: row.original.firstCheckedIn,
      uuid: row.original.uuid,
      Lat: row.original.latitude,
      Lng: row.original.longitude,
      ProjectId: row.original.project
    }));
    return data;
  }

  const handleExportRowsCSV = () => {
    const data = prepareExportTableData();
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleExportRowsPDF = () => {
    const doc = new jsPDF('l');
    const data = prepareExportTableData().map(row => Object.values(row));

    const tableHeaders = columns.map((c) => c.header);
    doc.autoTable({
      head: [tableHeaders],
      body: data,

      columnStyles: {
        0: { halign: 'center', cellWidth: 30, overflow: 'linebreak' },
        1: { halign: 'center', cellWidth: 22, overflow: 'linebreak' },
        2: { halign: 'center', cellWidth: 22, overflow: 'linebreak' },
        3: { halign: 'center', cellWidth: 30, overflow: 'linebreak' },
        4: { halign: 'center', cellWidth: 25, overflow: 'linebreak' },
        5: { halign: 'center', cellWidth: 25, overflow: 'linebreak' },
        6: { halign: 'center', cellWidth: 30, overflow: 'linebreak' },
        7: { halign: 'center', cellWidth: 22, overflow: 'linebreak' },
        8: { halign: 'center', cellWidth: 22, overflow: 'linebreak' },
        10: { halign: 'center', cellWidth: 30, overflow: 'linebreak' },

      },
      cellWidth: 'auto',
      headStyles: {
        halign: 'center',
        valign: 'middle',
        fontSize: 10,
      },
    });

    doc.save(`${anomaly}-report.pdf`);
  };

  useEffect(() => {
    if (anomaly === null) setTableData(null)

    if (anomaly)
      setTableData(massageData(data.sort((a, b) => b.eventTs - a.eventTs)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Drawer
      className={classes.root}
      anchor='bottom'
      open={uiState.drawerAnomalyOpen}
      onClose={closeDrawerAnomaly}
    >
      <Container fixed>
        <Grid2 container spacing={0}>
          <Grid2 container direction='row' justifyItems='space-between' flexGrow={1}>
            <Grid2 alignContent='center'>
              <Typography className={classes.title}>
                {tableData && `${tableData.length}`} ASSETS {`${anomaly}`}
              </Typography>
            </Grid2>
            <Grid2 className={classes.closeButton}>
              <IconButton
                data-test='closeDrawerButton'
                onClick={closeDrawerAnomaly}
                color='inherit'
              >
                <SystemIcon name='icn_x' color='white' />
              </IconButton>
            </Grid2>
          </Grid2>
          <Grid2 xs={12} className={classes.tableWrap}>
            {tableData && (
              <MaterialReactTable table={anomalyTable} />
            )}
          </Grid2>
        </Grid2>
      </Container>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: 3,
            backgroundColor: theme.colors.backgroundColor,
            border: `1px solid white`,
          },
        }}
      >
        <Grid2 container direction='column' className={classes.popover}>
          <Button
            onClick={handleExportRowsCSV}
            sx={{
              color: 'white',
              ':hover': {
                backgroundColor: theme.colors.lightSteel
              },
            }}
          >
            Export as CSV
          </Button>
          <Button
            onClick={handleExportRowsPDF}
            sx={{
              color: 'white',
              ':hover': {
                backgroundColor: theme.colors.lightSteel
              }
            }}
          >
            Export as PDF
          </Button>
        </Grid2>
      </Popover>
    </Drawer>
  )
}
