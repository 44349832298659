import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import { Fade } from '@mui/material'

import ProtectedRoute from './elements/ProtectedRoute.js'
import NavDrawer from './elements/NavDrawer/index.js'

import DashboardPage from './pages/DashboardPage.js'
import NewProjectDialog from './dialogs/NewProjectDialog/index.js'
import ProjectListPage from './pages/ProjectListPage.js'
import ProjectPage from './pages/ProjectPage.js'
import AssetPage from './pages/AssetPage.js'
import OwnersPage from './pages/OwnersPage.js'
import CustomersPage from './pages/CustomersPage.js'
import GlobalAssetTypesPage from './pages/GlobalAssetTypesPage.js'
import UsersPage from './pages/UsersPage.js'
import MyAccountPage from './pages/MyAccountPage.js'
import { useCustomerActions } from '../store/Customer/CustomerActions.js'
import { useConstantsActions } from '../store/Constants/ConstantsActions.js'
import { AddEditProjectPage } from './pages/AddEditProjectPage.jsx'
import SearchResultsPage from './pages/SearchResultsPage.js'

export default () => {
  const { getCustomers } = useCustomerActions()
  const { getConstants } = useConstantsActions()

  useEffect(() => {
    getCustomers()
    getConstants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <NavDrawer />
      {/* <Fade> */}
      <Routes>
        <Route path='/' exact element={<DashboardPage />} />
        <Route path='/Projects' exact element={<ProjectListPage />} />
        <Route path='/Projects/:id' element={<ProjectPage />} />
        <Route path='/Project/:id' element={<AddEditProjectPage />} />

        <Route path='/Asset/:id' element={<AssetPage />} />
        <Route path='/Account' element={<MyAccountPage />} />
        <Route path='/SearchResults' element={<SearchResultsPage />} />
        <Route
          path='/Owners'
          element={
            <ProtectedRoute groups={'admins'}>
              <OwnersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Customers'
          element={
            <ProtectedRoute groups='admins'>
              <CustomersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/GlobalAssetTypes'
          element={
            <ProtectedRoute groups='admins'>
              <GlobalAssetTypesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path='/Users'
          element={
            <ProtectedRoute groups={/admins|customerAdmin[\S]+/}>
              <UsersPage />
            </ProtectedRoute>
          }
        />
      </Routes>
      {/* </Fade> */}
      <NewProjectDialog />
    </Router>
  )
}
