/* eslint-disable no-unused-vars */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      border: `1px solid ${theme.colors.lightSteel}`,
      width: 350,
    },
  },
  dialogBackground: {
    backgroundColor: theme.colors.backgroundColor,
  },
  dialogTitle: {
    color: 'white',
  },
  label: {
    '&.MuiStepLabel-root .MuiStepLabel-label': {
      color: 'white',
    },
  },
}))

const CreateNewClientDialog = ({ open, closeForm, handleSubmit }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [clientName, setClientName] = useState('')

  return (
    <Dialog open={open} onClose={closeForm} className={classes.dialog}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(clientName)
        }}
        className={classes.dialogBackground}
      >
        <DialogTitle className={classes.dialogTitle}>
          Add new client
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Client Name'
            value={clientName}
            onChange={({ target }) => {
              setClientName(target.value)
            }}
            sx={{ ...theme.textfield, marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            onClick={() => {
              setClientName('')
              closeForm()
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' sx={{ borderRadius: 5 }} type='submit'>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateNewClientDialog
