import React, { useEffect } from 'react'
import { useUIActions } from '../../store/UI/UIActions.js'
import { useProjectActions } from '../../store/Project/ProjectActions.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'
import { useStateContext } from '../../store/stateContext.js'

import AsseTracAppBar from '../elements/AsseTracAppBar.js'

import { Container, Grid2, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SystemIcon from '../elements/SystemIcon.js'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useNavigate } from 'react-router-dom'
import ProjectTable from '../tables/ProjectTable.jsx'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  addProjBtn: {
    marginLeft: 'auto',
  },
  Typography: {
    color: theme.colors.portlandCement,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  projectListContainer: {
    marginTop: theme.spacing(2),
  },
  inactiveContainer: {
    marginTop: theme.spacing(14),
  },
}))

export default () => {
  const classes = useStyles()
  const { memberOf } = useAuthActions()
  const { openNewProjectDialog } = useUIActions()
  const { getProjectList } = useProjectActions()
  const { state } = useStateContext()
  const navigate = useNavigate()

  const projectsState = state.projects

  useEffect(() => {
    getProjectList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsState.projectList])

  return (
    <Grid2 container direction='column'>
      <AsseTracAppBar  />
      <Container className={classes.container}>
        {/* TODO: ask if this should dissapear at all */}
        {projectsState.projectList.length === 0 &&
          projectsState.inactiveProjectList.length === 0 && (
            <Grid2 container spacing={4} alignItems='center'>
              {memberOf(/admins|customerAdmin[\S]+/) && (
                <>
                  <Grid2 xs={12}>
                    <Typography
                      variant='h4'
                      align='center'
                      data-test='initialProjectBanner'
                      className={classes.Typography}
                    >
                      Looks like we're going to have to start fresh
                    </Typography>
                  </Grid2>
                  <Grid2>
                    <Button
                      color='primary'
                      variant='contained'
                      data-test='initialProjectCreateButton'
                      onClick={openNewProjectDialog}
                    >
                      Get to Work!
                    </Button>
                  </Grid2>
                </>
              )}
            </Grid2>
          )}
        {(projectsState.projectList.length > 0 ||
          projectsState.inactiveProjectList.length > 0) && (
          <Grid2
            container
            direction='column'
            sx={{ paddingX: 5, marginTop: 2 }}
            spacing={2}
          >
            <Grid2 container justifyContent='space-between'>
              <Button
                variant='text'
                startIcon={<ChevronLeftIcon />}
                onClick={() => navigate('/')}
                color='secondary'
              >
                Back to Map
              </Button>
              {memberOf(/admins|customerAdmin[\S]+/) && (
                <Button
                  color='primary'
                  className={classes.addProjBtn}
                  variant='contained'
                  data-test='projectCreateButton'
                  onClick={() => navigate('/Project/new')}
                  sx={{ borderRadius: 5 }}
                  startIcon={<SystemIcon name='icn_add' color='white' />}
                >
                  Add Project/Yard
                </Button>
              )}
            </Grid2>
            <Grid2>
              <ProjectTable projects={projectsState.projectList} />
            </Grid2>
          </Grid2>
        )}
      </Container>
    </Grid2>
  )
}
