/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { makeStyles, useTheme } from '@mui/styles'
import { useForm } from '../../hooks/Forms'
import { useNavigate, useParams } from 'react-router-dom'
import { useProjectActions } from '../../store/Project/ProjectActions'
import AsseTracAppBar from '../elements/AsseTracAppBar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import dayjs from 'dayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilePicker from '../elements/FilePicker'
import moment from 'moment'
import SystemIcon from '../elements/SystemIcon'
import CreateNewClientDialog from '../dialogs/CreateNewClientDialog'
import { useSnackbar } from 'notistack'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  datepicker: {
    width: '100%',
    '& .MuiInputBase-root': {
      color: 'white',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'white',
      opacity: 1,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputBase-input': {
      color: 'white',
      padding: theme.spacing(1, 0, 1, 0),
      paddingLeft: `calc(0.5em + ${theme.spacing(0.5)})`,
    },
  },
  radioButtons: {
    '& .MuiSvgIcon-root': {
      color: 'gray',
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
    '&.Mui-checked': {
      color: 'rgba(128, 128, 128, 1)',
    },
  },
  textfield: {
    '& .MuiInputBase-input': {
      color: 'white',
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(0.5em + ${theme.spacing(0.5)})`,
      width: '35ch',
    },
    '&:hover .MuiOutlinedInput-root .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

export const AddEditProjectPage = () => {
  const theme = useTheme()
  const classes = useStyles()

  const { id } = useParams()
  const navigate = useNavigate()

  const { getClientList, getProject, updateProject } = useProjectActions()
  const { enqueueSnackbar } = useSnackbar()

  const { getValue, handleInputChange, setInputs, inputs, handleSubmit } =
    useForm()

  const [clientList, setClientList] = useState([])
  const [projectForm, setProjectForm] = useState(null)
  const [openAddNewClientDialog, setOpenAddNewClientDialog] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const clients = getClientList()
    setClientList(clients)
  }, [getClientList])

  const submitForm = async (form) => {
    try {
      setIsSaving(true)
      if (id !== 'new') {
        await updateProject({ ...form, id })
        enqueueSnackbar('Successfully updated project.', {
          variant: 'success',
        })
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      })
    }
    setIsSaving(false)
  }

  useEffect(() => {
    if (id !== 'new') {
      const project = getProject(id)
      if (project) {
        setProjectForm({
          ...project,
          startDate: dayjs(moment(project.startDate).toDate()),
          endDate: dayjs(moment(project.endDate).toDate()),
        })
      }
    } else {
      setProjectForm({
        name: '',
        status: '',
        startDate: null,
        endDate: null,
        description: '',
        client: '',
        type: '',
      })
    }
  }, [id, getProject])

  if (!projectForm) {
    return <></>
  }

  return (
    <form onSubmit={(e) => handleSubmit(submitForm, e)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid2 container sx={{ color: 'white' }}>
          <AsseTracAppBar
            title={id === 'new' ? 'ADD PROJECT' : 'EDIT PROJECT'}
          />
          <Grid2
            sx={{
              marginTop: 3,
              marginLeft: 0,
              paddingX: 5,
              width: '100%',
            }}
          >
            {/* Back Button */}
            <Button
              variant='text'
              startIcon={<ChevronLeftIcon />}
              onClick={() => navigate('/Projects')}
              color='secondary'
            >
              Back to Project/Yards
            </Button>

            {/* Title */}
            <Typography color='secondary' variant='h6' sx={{ marginTop: 3 }}>
              PROJECT/YARD DETAILS
            </Typography>

            {/* Type and Status */}
            <Grid2 container sx={{ marginTop: 1 }} spacing={5}>
              <Grid2>
                <FormControl>
                  <Grid2
                    container
                    direction='row'
                    sx={{ color: 'white' }}
                    alignItems='center'
                    spacing={2}
                  >
                    <FormLabel
                      id='demo-row-radio-buttons-group-label'
                      sx={{ color: 'white' }}
                      color='secondary'
                    >
                      Type:
                    </FormLabel>
                    <RadioGroup
                      color='secondary'
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      value={getValue('type', projectForm.type)}
                      onChange={handleInputChange('type')}
                    >
                      <FormControlLabel
                        value='project'
                        control={<Radio className={classes.radioButtons} />}
                        label='Project'
                      />
                      <FormControlLabel
                        value='yard'
                        control={<Radio className={classes.radioButtons} />}
                        label='Yard'
                      />
                    </RadioGroup>
                  </Grid2>
                </FormControl>
              </Grid2>
              <Grid2>
                <FormControl>
                  <Grid2
                    container
                    direction='row'
                    sx={{ color: 'white' }}
                    alignItems='center'
                    spacing={2}
                  >
                    <FormLabel
                      id='demo-row-radio-buttons-group-label'
                      sx={{ color: 'white' }}
                      color='secondary'
                    >
                      Status:
                    </FormLabel>
                    <RadioGroup
                      row
                      color='secondary'
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      value={getValue('status', projectForm.status)}
                      onChange={handleInputChange('status')}
                    >
                      <FormControlLabel
                        value='active'
                        control={<Radio className={classes.radioButtons} />}
                        label='Active'
                      />
                      <FormControlLabel
                        value='inactive'
                        control={<Radio className={classes.radioButtons} />}
                        label='Inactive'
                      />
                    </RadioGroup>
                  </Grid2>
                </FormControl>
              </Grid2>
            </Grid2>

            {/* Name, Client and Dates */}
            <Grid2
              container
              columnSpacing={3}
              rowSpacing={1}
              sx={{ marginTop: 2 }}
            >
              <Grid2 container direction='column' flexGrow={1}>
                <Typography>Project/Yard Name</Typography>
                <TextField
                  color='secondary'
                  variant='outlined'
                  className={classes.textfield}
                  sx={theme.textfield}
                  value={getValue('name', projectForm.name)}
                  onChange={handleInputChange('name')}
                />
              </Grid2>
              <Grid2 container direction='column' width={'35ch'}>
                <Typography>Client</Typography>
                <FormControl>
                  <Grid2 container direction='row'>
                    <Select
                      displayEmpty
                      color='secondary'
                      input={<OutlinedInput />}
                      inputProps={{ 'aria-label': 'Without label' }}
                      MenuProps={MenuProps}
                      className={classes.textfield}
                      value={getValue('client', projectForm.client)}
                      onChange={handleInputChange('client')}
                      sx={{
                        ...theme.textfield,
                        '& .MuiInputBase-input': {
                          width: '26ch',
                        },
                        '& .MuiSvgIcon-root': {
                          color: theme.colors.lightSteel,
                        },
                      }}
                      IconComponent={(props) => <ExpandMoreIcon {...props} />}
                    >
                      {clientList.map((client) => (
                        <MenuItem key={client} value={client}>
                          {client}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      onClick={() => setOpenAddNewClientDialog(true)}
                      color='inherit'
                    >
                      <SystemIcon name='icn_add' color='white' />
                    </IconButton>
                  </Grid2>
                </FormControl>
              </Grid2>
              <Grid2 container width={'20ch'}>
                <Typography>Start Date</Typography>
                <DatePicker
                  className={classes.datepicker}
                  id='start-date'
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                  margin='normal'
                  name='startDate'
                  data-test='createProjectDialogStartDate'
                  format='MM.DD.YYYY'
                  onChange={handleInputChange('startDate')}
                  value={getValue('startDate', projectForm.startDate)}
                />
              </Grid2>
              <Grid2 container width={'20ch'}>
                <Typography>End Date</Typography>
                <DatePicker
                  className={classes.datepicker}
                  id='end-date'
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                  format='MM.DD.YYYY'
                  margin='normal'
                  name='endDate'
                  data-test='createProjectDialogStartDate'
                  onChange={handleInputChange('endDate')}
                  value={getValue('endDate', projectForm.endDate)}
                />
              </Grid2>
            </Grid2>

            {/* Description */}
            <Grid2
              container
              sx={{ marginTop: 2 }}
              direction='column'
              spacing={1}
            >
              <Typography>Project/Yard Description</Typography>
              <TextField
                id='outlined-multiline-static'
                //   label='Multiline'
                multiline
                rows={3}
                color='secondary'
                sx={theme.textfield}
                onChange={handleInputChange('description')}
                value={getValue('description', projectForm.description)}
              />
            </Grid2>
            {/* Configuration Details */}
            <Typography variant='h6' sx={{ marginTop: 3 }}>
              CONFIGURATION DETAILS
            </Typography>
            <Grid2
              container
              sx={{ marginTop: 2 }}
              direction='column'
              spacing={1}
            >
              <Typography>Project/Yard Description</Typography>
              <FilePicker
                //   error={!!getFieldError('kmlFile')}
                extensions={['kml', 'kmz']}
                helperText='Upload a kml or kmz file'
                label='Project Boundaries'
                //   onChange={handleChange}
              />
            </Grid2>

            {/* Cancel and Save Buttons */}
            <Grid2
              container
              sx={{ marginTop: 10 }}
              flexGrow={1}
              justifyContent='space-between'
            >
              <Button
                variant='text'
                onClick={() => navigate('/Projects')}
                color='secondary'
                sx={{ borderRadius: 5 }}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                sx={{ borderRadius: 5 }}
                disabled={isSaving}
              >
                Save
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
        <CreateNewClientDialog
          open={openAddNewClientDialog}
          closeForm={() => setOpenAddNewClientDialog(false)}
          handleSubmit={(newclient) => {
            setClientList((prev) => [...prev, newclient])
            setInputs({ ...inputs, client: newclient })
            setOpenAddNewClientDialog(false)
          }}
        />
      </LocalizationProvider>
    </form>
  )
}
