import React from 'react'
import PropTypes from 'prop-types'
import { alpha, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles, useTheme, } from '@mui/styles'

import AssetIcon from './AssetIcon.js'
import AssetIconMap from '../../assets/icons/asset-icon-map.json'

const useStyles = makeStyles((theme) => ({
  select: {
    minHeight: 56,
    '&>div': {
      paddingTop: 14,
      paddingBottom: 14,
    },
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiSelect-select': {
      color: 'white',
    },
  },
  dropdownStyle: {
    width: theme.spacing(38),
    '& .MuiList-root': {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: theme.colors.backgroundColor,
      border: '1px solid white',
      borderRadius: 5,
      justifyContent: 'center',
    },
  },

}))

const AssetIconSelect = ({ onChange = () => { }, value = '' }) => {
  const classes = useStyles()
  const theme = useTheme();

  const menuItemStyle = {
    '&.Mui-selected': {
      backgroundColor: theme.colors.lightSteel,
      '&:hover': {
        backgroundColor: theme.colors.lightSteel,
      },
    },
    '&:hover': {
      backgroundColor: alpha(theme.colors.lightSteel, 0.5),
    },
  }
  return (
    <FormControl fullWidth variant='outlined'>
      <InputLabel sx={{
        color: 'white', '&.Mui-focused': {
          color: 'white',
        },
      }}>Icon</InputLabel>
      <Select
        className={classes.select}
        fullWidth
        labelId='icon-select-label'
        label='Icon'
        onChange={onChange}
        value={value}
        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        sx={{
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
         
        }}
      >
        <MenuItem value='' sx={{ color: 'white' }}>None</MenuItem>

        {AssetIconMap.icons.map((icon) => (
          <MenuItem key={icon.key} value={icon.key} sx={menuItemStyle}>
            <AssetIcon name={icon.key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

AssetIconSelect.propTypes = {
  onChange: PropTypes.func,
}

export default AssetIconSelect
