import { uploadData } from 'aws-amplify/storage'
import awsConfig from '../../aws-exports.js'
import * as moment from 'moment'
import { useStateContext } from '../stateContext.js'
import * as mutations from '../../graphql/mutations.js'
import { dateToUnix } from '../../util.js'
import { useCustomerActions } from '../Customer/CustomerActions.js'
import { generateClient } from 'aws-amplify/api'
import { useCallback } from 'react'
export const useProjectActions = () => {
  const { state } = useStateContext()
  const { getActiveCustomer } = useCustomerActions()

  const awsClient = generateClient()

  const getProjectList = useCallback(() => {
    return state.projects.projectList
  }, [state.projects.projectList])

  const getInactiveProjectList = () => {
    return state.projects.inactiveProjectList
  }

  const getClientList = useCallback(() => {
    const data = getProjectList()
    var clients = data.reduce((acc, curr) => {
      acc.push(curr.client.trim())
      return acc
    }, [])

    // de-dupe the array
    clients = [...new Set(clients)]
    return clients
  }, [getProjectList])

  const getProject = useCallback(
    (id) => {
      // const { data, errors } = await API.graphql(
      //   graphqlOperation(queries.getProject, { id })
      // )
      // const projectList = safeGet('projects.projectList', state)
      const projectList = state.projects.projectList ?? []
      // const inactiveProjectList = safeGet('projects.inactiveProjectList', state)
      const inactiveProjectList = state.projects.inactiveProjectList ?? []

      // console.log(tempList)
      let project = projectList.find((project) => project.id === id)
      if (!project) {
        project = inactiveProjectList.find((project) => project.id === id)
      }
      // console.log(project)
      if (project && project.startDate && !moment.isMoment(project.startDate)) {
        project.startDate = moment.unix(project.startDate)
      }
      if (project && project.endDate && !moment.isMoment(project.endDate)) {
        project.endDate = moment.unix(project.endDate)
      }
      //console.log(project)

      return project
    },
    [state.projects.projectList, state.projects.inactiveProjectList],
  )

  const createProject = async ({
    name,
    client,
    description,
    boundaries,
    overlays,
    startDate,
    endDate,
    // status,
  }) => {
    const customer = await getActiveCustomer()

    const id =
      name.trim().replace(/ /g, '_').toLowerCase() + '_' + new Date().getTime()
    // only query with properties in schema
    const input = {
      id: id,
      customerId: customer.id,
      name: name.trim(),
      client: client.trim(),
      description,
      boundaries,
      overlays,
      startDate: dateToUnix(startDate),
      endDate: dateToUnix(endDate),
      status: 'activating',
      userGroups: customer.userGroups,
      adminGroups: customer.adminGroups,
    }
    const project = await awsClient.graphql({
      query: mutations.createProject,
      variables: { input },
    })

    return project
  }

  const updateProject = async ({
    id,
    name,
    type,
    client,
    description,
    boundaries,
    overlays,
    startDate,
    endDate,
    status,
  }) => {
    // only query with properties in schema
    const input = {
      id,
      name,
      type,
      client,
      description,
      boundaries,
      overlays,
      startDate: dateToUnix(startDate),
      endDate: dateToUnix(endDate),
      status: status || 'active',
    }

    for (let prop in input) {
      if (typeof input[prop] === 'undefined') {
        Reflect.deleteProperty(input, prop)
      }
    }

    const project = await awsClient.graphql({
      query: mutations.updateProject,
      variables: {
        input,
      },
    })

    return project
  }

  const uploadOverlay = async ({
    name,
    file,
    structure,
    directory,
    project,
    progressCallback,
  }) => {
    try {
      const customer = await getActiveCustomer()
      const nameNoExtension = file.name.replace(/\.[^/.]+$/, '')
      const fileName = `${directory}/${nameNoExtension}/${file.name}`
      uploadData({
        path: fileName,
        data: file,
      })
      const geoJsonFileName = `${directory}/${nameNoExtension}/${nameNoExtension}.json`
      const geoJsonS3Obj = await uploadData({
        path: geoJsonFileName,
        data: JSON.stringify(structure),
        options: {
          onProgress: progressCallback,
          contentType: 'application/json',
        },
      }).result
      if (
        project.overlays &&
        project.overlays.some((overlay) => overlay.name === name)
      ) {
        // this is an update of an existing overlay, no need to update the project
        return project
      }
      geoJsonS3Obj.bucket = awsConfig.aws_user_files_s3_bucket
      geoJsonS3Obj.region = awsConfig.aws_user_files_s3_bucket_region

      const newOverlay = {
        name,
        file: {
          key: geoJsonFileName,
          bucket: awsConfig.aws_user_files_s3_bucket,
          region: awsConfig.aws_user_files_s3_bucket_region,
        },
      }
      const input = {
        id: project.id,
        name: project.name,
        customerId: customer.id,
        client: project.client,
        description: project.description,
        boundaries: project.boundaries.map(({ latitude, longitude }) => ({
          latitude,
          longitude,
        })),
        overlays: [...(project.overlays || []), newOverlay],
        startDate: dateToUnix(project.startDate),
        endDate: dateToUnix(project.endDate),
        status: project.status,
        userGroups: customer.userGroups,
        adminGroups: customer.adminGroups,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }
      const { data } = await awsClient.graphql({
        query: mutations.updateProject,
        variables: { input },
      })
      const { updateProject } = data
      return {
        ...updateProject,
        startDate: moment.isMoment(updateProject.startDate)
          ? updateProject.startDate
          : moment.unix(updateProject.startDate),
        endDate: moment.isMoment(updateProject.endDate)
          ? updateProject.endDate
          : moment.unix(updateProject.endDate),
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    createProject,
    getProject,
    getProjectList,
    getClientList,
    updateProject,
    uploadOverlay,
    getInactiveProjectList,
  }
}
