import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  alpha,
  Button,
  Container,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import AsseTracAppBar from '../elements/AsseTracAppBar.js'
import SystemIcon from '../elements/SystemIcon.js'
import CreateCustomerDialog from '../dialogs/CreateCustomerDialog.js'
import { useCustomerActions } from '../../store/Customer/CustomerActions.js'
import { useStateContext } from '../../store/stateContext.js'
import {
  canceledPromiseErrorName,
  useCancellablePromise,
} from '../../promiseUtils.js'

const useStyles = makeStyles((theme) => ({
  alignRight: {
    textAlign: 'right',
  },
  container: {
    margin: 0,
    marginTop: theme.spacing(10),
  },
  pageWrap: {
    height: '100%',
  },
  header: {
    justifyContent: 'space-between',
  },
  listContainer: {
    marginTop: theme.spacing(2),
    // backgroundColor: 'yellow'
  },
  selectBox: {
    textAlign: 'left',
    minWidth: theme.spacing(16),
  },
  select: {
    color: 'white',
    width: 130,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.lightSteel,
    },
    '& .MuiSelect-select': {
      color: 'white',
    },
  },
  spinnerContainer: {
    minHeight: theme.spacing(25),
  },
  dropdownStyle: {
    // width: theme.spacing(38),
    '& .MuiList-root': {
      // display: 'flex',
      // flexWrap: 'wrap',
      color: 'white',
      backgroundColor: theme.colors.backgroundColor,
      border: '1px solid white',
      borderRadius: 5,
      justifyContent: 'center',
    },
  },
}))

const CustomersPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const { setActiveCustomer, updateCustomer } = useCustomerActions()
  const { state } = useStateContext()
  const { cancellablePromise } = useCancellablePromise()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [selected, setSelected] = useState(0)
  const [customers, setCustomers] = useState([])

  const handleStatusChange = async (customer, status) => {
    try {
      const allowedStatuses = ['Active', 'Inactive', 'Suspended']

      if (allowedStatuses.indexOf(status) < 0) {
        console.error('Status invalid.')
        return
      }

      await cancellablePromise(
        updateCustomer({
          id: customer.id,
          status,
        }),
      )
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  const handleCustomerSelect = async (index) => {
    try {
      setSelected(index)
      await cancellablePromise(setActiveCustomer(customers[index]))
      navigate('/')
    } catch (error) {
      if (error.name === canceledPromiseErrorName) {
        return
      }
      console.error(error)
    }
  }

  useEffect(() => {
    setCustomers(state.customers.customerList)
    if (state.customers.activeCustomer) {
      setSelected(
        state.customers.customerList.findIndex(
          (customer) => customer.id === state.customers.activeCustomer.id,
        ),
      )
    }
  }, [state.customers])

  const menuItemStyle = {
    '&.Mui-selected': {
      backgroundColor: theme.colors.lightSteel,
      '&:hover': {
        backgroundColor: theme.colors.lightSteel,
      },
    },
    '&:hover': {
      backgroundColor: alpha(theme.colors.lightSteel, 0.5),
    },
  }

  return (
    <>
      <Grid2
        container
        direction='column'
        className={classes.pageWrap}
        width='50%'
      >
        <AsseTracAppBar title='CUSTOMERS' />
        <Container maxWidth='sm' className={classes.container}>
          <Grid2 container className={classes.header}>
            <Grid2 xs={6}>
              <Typography
                color='secondary'
                variant='subtitle1'
                data-test='customerTotal'
              >
                {customers.length}
                {customers.length === 1 ? (
                  <span> customer</span>
                ) : (
                  <span> total customers</span>
                )}
              </Typography>
            </Grid2>
            <Grid2 xs={6} className={classes.alignRight}>
              <Button
                color='primary'
                onClick={() => setCreateDialogOpen(true)}
                variant='contained'
                data-test='createCustomerButton'
                sx={{ borderRadius: 5 }}
              >
                <SystemIcon name='icn_add' color='white' /> ADD CUSTOMER
              </Button>
            </Grid2>
          </Grid2>

          <Grid2
            container
            spacing={2}
            className={classes.listContainer}
            direction='column'
          >
            {customers.map((customer, index) => (
              <Grid2
                container
                key={`customer-row-${index}`}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid2 xs={2} container>
                  <Switch
                    checked={selected === index}
                    onChange={() => handleCustomerSelect(index)}
                    color='primary'
                  />
                  <Typography color='secondary' variant='subtitle1'>
                    {customer.name}
                  </Typography>
                </Grid2>

                <Grid2 xs={4} className={classes.alignRight}>
                  <FormControl variant='outlined' className={classes.selectBox}>
                    <Select
                      onChange={(e) =>
                        handleStatusChange(customer, e.target.value)
                      }
                      value={customer.status}
                      className={classes.select}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem sx={menuItemStyle} value='Active'>
                        Active
                      </MenuItem>
                      <MenuItem sx={menuItemStyle} value='Inactive'>
                        Inactive
                      </MenuItem>
                      <MenuItem sx={menuItemStyle} value='Suspended'>
                        Suspended
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
              </Grid2>
            ))}
          </Grid2>
        </Container>
      </Grid2>
      <CreateCustomerDialog
        open={createDialogOpen}
        closeForm={() => setCreateDialogOpen(false)}
      />
    </>
  )
}

export default CustomersPage
