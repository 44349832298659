/* eslint-disable no-unused-vars */

import { IconLayer } from '@deck.gl/layers'
import { CompositeLayer } from '@deck.gl/core'

import ClusterBubble from '../assets/img/svg/cluster_bubble.svg'

import { safeGet } from '../util.js'

export default class AssetLayer extends CompositeLayer {
  renderLayers() {
    return [
      new IconLayer({
        data: this.props.data,
        getIcon: () => {
          return {
            url: ClusterBubble,
            width: 40,
            height: 49,
            anchorY: 49,
          }
        },
        getPosition: (d) => d.geometry.coordinates,
        getSize: 55,
        id: `bubble-icon-layer-${this.id}`,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
      new IconLayer({
        data: this.props.data,
        getIcon: (d) => d.properties.icon + '.svg',
        iconAtlas: '/icons/icons.png',
        iconMapping: this.props.iconMapping,
        getPosition: (d) => d.geometry.coordinates,
        getSize: 35,
        id: `icon-layer-${this.id}`,
        opacity: 1,
        pickable: true,
        widthMinPixels: 2,
        widthScale: 20,
      }),
    ]
  }

  getPickingInfo({ info, mode }) {
    switch (mode) {
      case 'hover': {
        const onTooltip = safeGet('layer.props.onTooltip', info) || (() => {})
        if (
          info.object &&
          info.object.properties &&
          !info.object.properties.cluster
        ) {
          onTooltip(
            info.object ? { ...info.object, x: info.x, y: info.y } : null,
          )
        } else {
          onTooltip(null)
        }
        break
      }
      case 'query': {
        if (typeof this.onClick === 'function') {
          this.onClick(info)
        }
        const onTooltipQuery =
          safeGet('layer.props.onTooltip', info) || (() => {})
        onTooltipQuery(null)
        break
      }
      default:
        break
    }
    return info
  }
}

AssetLayer.layerName = 'AssetLayer'
