/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Map, NavigationControl, useControl } from 'react-map-gl'
import { makeStyles } from '@mui/styles'
import { useResizeDetector } from 'react-resize-detector'

import get from 'lodash/get.js'

import { useStateContext } from '../../store/stateContext.js'
import { useMapActions } from '../../store/Map/MapActions.js'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapboxOverlay } from '@deck.gl/mapbox'

function DeckGLOverlay(props) {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
}))

const AsseTracMap = ({ onInitialize }) => {
  const classes = useStyles()

  const { state, dispatch } = useStateContext()
  const { updateViewport, mapRef, updateCurrentMapId } = useMapActions()

  const [shouldInit, setShouldInit] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const deckGlRef = useRef(null)

  const { ref, width, height } = useResizeDetector()
  const initialize = () => {
    if (initialized) {
      return
    }

    if (typeof onInitialize === 'function') {
      onInitialize()
    }
    setInitialized(true)
  }

  // const onResize = (width, height) => {
  //   updateViewport({ width, height })
  //   setShouldInit(true)
  // }

  const onMapLoad = () => {
    const activeRef = get(deckGlRef, 'current.children[1].ref.current')
    if (activeRef) {
      const map = activeRef.getMap()
      updateCurrentMapId(map._mapId)
    }
  }

  // init
  useEffect(() => {
    if (shouldInit) {
      initialize()
      setShouldInit(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldInit])

  useEffect(() => {
    if (width && height !== undefined && !initialized) {
      updateViewport({ width, height })
      setShouldInit(true)
    }
  }, [width, height, updateViewport, initialized])

  return (
    <div className={classes.root} ref={ref}>
      {initialized && (
        <Map
          mapboxAccessToken={state.map.config.mapboxApiAccessToken}
          mapStyle={state.map.config.mapStyle}
          ref={mapRef}
          onLoad={onMapLoad}
          controller
          viewState={state.map.viewport}
          onMove={(evt) => updateViewport(evt.viewState)}
        >
          <DeckGLOverlay
            viewState={state.map.viewport}
            layers={state.map.filteredLayers}
            interleaved
            // debug
          />
          <NavigationControl
            showZoom
            position='bottom-right'
            showCompass={false}
            style={{
              marginRight:
                window.location.pathname === '/' ||
                state.ui.projectMapFullScreen
                  ? 40
                  : 350,
            }}
          />
        </Map>
      )}
    </div>
  )
}

AsseTracMap.propTypes = {
  filters: PropTypes.array,
  onInitialize: PropTypes.func,
}

export default AsseTracMap
