/* eslint-disable no-unused-vars */

import React, { createRef, useState } from 'react'
import { Button, Grid2, TextField } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import SystemIcon from './SystemIcon.js'

const useStyles = makeStyles((theme) => ({
  textField: {
    pointerEvents: 'none',
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.lightSteel,
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'white',
    },
    '& .MuiFormHelperText-root': {
      color: 'white',
    },
  },
  fileInput: {
    display: 'none',
  },
}))

const FilePicker = ({
  error,
  extensions = [],
  file = {},
  helperText,
  label,
  onChange = () => {},
}) => {
  const [selectedFile, setSelectedFile] = useState(file)
  const classes = useStyles()
  const theme = useTheme()

  const fileInput = createRef()

  const browse = () => {
    fileInput.current.click()
  }

  const onSelect = (e) => {
    const file = e.target.files[0]
    // back out if user hits cancel button on finder window
    if (!file) {
      return
    }

    setSelectedFile(file)
    onChange(file)
  }

  const accept =
    extensions.length === 0
      ? '*'
      : extensions.map((item) => `.${item}`).join(',')

  return (
    <Grid2 container spacing={3}>
      <Grid2
        sx={{
          flexGrow: 1,
        }}
      >
        <TextField
          sx={{
            ...theme.textfield,
            '& .MuiInputBase-input': {
              padding: theme.spacing(1, 1, 1, 0),
              paddingLeft: `calc(1em + ${theme.spacing(1)})`,
            },
            '&:hover .MuiInputBase-input': {},
          }}
          error={error}
          fullWidth
          // helperText={helperText}
          // label={label || 'Select File'}
          // margin='normal'
          readOnly
          value={selectedFile.name || ''}
          variant='outlined'
        />
      </Grid2>
      <Grid2 sx={{ alignItems: 'center', display: 'flex' }}>
        <Button
          variant='outlined'
          color='secondary'
          onClick={browse}
          sx={{ borderRadius: 5 }}
          startIcon={<SystemIcon name='icn_add' color='white' />}

        >
          Browse
        </Button>
        <input
          accept={accept}
          className={classes.fileInput}
          onChange={onSelect}
          ref={fileInput}
          type='file'
          data-test='filePickerInput'
        />
      </Grid2>
    </Grid2>
  )
}

export default FilePicker
