/* eslint-disable no-unused-vars */

import React from 'react'
import clsx from 'clsx'
import { IconButton, SvgIcon } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import Icons from '../../assets/icons/symbol-defs.svg'

const useStyles = makeStyles((theme) => ({

  alert: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 2,
    right: 2,
    height: theme.spacing(1),
    width: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
}))

const ToolbarToggleButton = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const theme = useTheme()
  const lit = props.lit || false

  return (
    <>
      <IconButton
        id={props.id}
        onClick={props.onClick}
        ref={ref}
        size='small'
        sx={{
          backgroundColor: lit ? theme.palette.primary.main : 'transparent',
          ":hover": {
            backgroundColor: lit ? theme.palette.primary.main : 'transparent',
          },
          marginLeft: 1
        }}
      >
        {props.alert && !props.lit && <div className={classes.alert} />}
        <SvgIcon color='secondary' sx={{ fill: 'white' }}>
          <svg>
            <use xlinkHref={Icons + props.icon} />
          </svg>
        </SvgIcon>
      </IconButton>
    </>
  )
})

export default ToolbarToggleButton
