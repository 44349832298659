import React from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SystemIcon from './SystemIcon.js'
import { useUIActions } from '../../store/UI/UIActions.js'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 50,
    left: '50%',
    marginLeft: -70,
  },
}))

export default () => {
  const classes = useStyles()
  const { openDrawerAnomaly } = useUIActions()

  return (
    <Button
      color='primary'
      variant='contained'
      size='small'
      elevation={12}
      className={classes.root}
      onClick={openDrawerAnomaly}
      sx={{borderRadius: 5}}
    >
      <SystemIcon name='icn_report' color='white' />
      Generate Report
    </Button>
  )
}
