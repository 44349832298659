import React, { useEffect } from 'react'

import Brand from '../../assets/brand/Matrac.svg'
import Hero from '../../assets/img/cable-clouds-danger-dawn-171428.jpg'

import { Grid2, Card, CardContent } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'

import LoginForm from '../forms/LoginForm.js'
import ResetPasswordForm from '../forms/ResetPasswordForm.js'
import ForgotPasswordForm from '../forms/ForgotPasswordForm.js'
import ForgotPasswordSubmitForm from '../forms/ForgotPasswordSubmitForm.js'

import { useStateContext } from '../../store/stateContext.js'
import { useAuthActions } from '../../store/Auth/AuthActions.js'

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'absolute',
    width: '100vw',
    height: '55%',
    backgroundImage: 'url(' + Hero + ')',
    backgroundSize: 'cover',
    backgroundPosition: '0% 94%',
    zIndex: -1,
  },
  card: {
    marginTop: 30,
    backgroundColor: theme.colors.backgroundColor,
  },
  brand: {
    // textAlign: 'center',
    // height: '100%',
    paddingBottom: 8,
    paddingTop: 8,
  },
  verticalGutters: {
    marginBottom: 8,
    marginTop: 8,
  },
  logo: {
    // maxHeight: 60,
    // maxWidth: 480,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}))

export default () => {
  const classes = useStyles()
  const theme = useTheme()

  const { state } = useStateContext()

  const { restoreSession } = useAuthActions()

  useEffect(() => {
    restoreSession()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid2
      container
      spacing={2}
      justifyContent='center'
      sx={{
        // backgroundColor: 'blue',
        height: '100vh',
      }}
    >
      <div className={classes.hero} />
      <Grid2
        xs={11}
        sm={8}
        md={6}
        lg={4}
        xl={3}
        sx={{
          // backgroundColor: 'blue',
          alignSelf: 'center',
        }}
      >
        <Card
          className={classes.card}
          elevation={16}
          sx={{
            backgroundColor: theme.colors.backgroundColor,
          }}
        >
          <CardContent>
            <Grid2
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <Grid2 container xs={12} className={classes.brand} alignItems={'center'}>
                <svg className={classes.logo} width='320px' height='80px' viewBox='0 0 199 10'>
                  <use xlinkHref={Brand + '#brand-icon'} />
                </svg>
              </Grid2>
              {state.auth.passwordChangeRequired && <ResetPasswordForm />}
              {state.auth.forgotPassword && <ForgotPasswordForm />}
              {state.auth.forgotPasswordSubmit && <ForgotPasswordSubmitForm />}
              {!state.auth.passwordChangeRequired &&
                !state.auth.forgotPassword &&
                !state.auth.forgotPasswordSubmit && <LoginForm />}
            </Grid2>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  )
}
